import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserInfo } from '@LIB_UTIL/auth/model/auth.model';
import { Store } from '@ngxs/store';
import { ModalService } from '@LIB_UTIL/services/modal.service';
import { launchReadOnlyModal } from '@LG_SHARED/utils';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class ReadOnlyGuard {
  constructor(
    private modalService: ModalService,
    private router: Router,
    private store: Store
  ) {}

  /**
   * Checks if account is ReadOnly and prevent access to certain pages
   */
  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const userInfo: UserInfo = this.store.selectSnapshot(AuthState.userInfo);

    if (!userInfo) {
      this.navigateToDashboard();
    }

    if (userInfo.isReadOnly) {
      launchReadOnlyModal(this.modalService);

      return false;
    }

    return true;
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
