import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { environment } from '@LIB_UTIL/environments/environment';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { clearSid } from '@LIB_UTIL/auth/util/auth.util';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(
    private authService: AuthService
  ) {}

  /**
   * Extract the "sid" from the url or from the sessionStorage
   * and store it in the authService. If there is no sid,
   * redirect the user to the login page.
   */
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    let { sid }: Params = route.queryParams;

    if (sid) {
      this.authService.setSid(sid);
    }

    // Check if we're not in embedded mode on the login page.
    if (!environment.embeddedMode && this.authService.sid === null) {
      clearSid();
      window.location.assign(environment.loginPageUrl);

      return false;
    }

    return true;
  }
}
