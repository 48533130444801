export default [
  'stroke-solid',
  'stroke-shortdash',
  'stroke-shortdot',
  'stroke-shortdashdot',
  'stroke-shortdashdotdot',
  'stroke-dot',
  'stroke-dash',
  'stroke-longdash',
  'stroke-dashdot',
  'stroke-longdashdot',
  'stroke-longdashdotdot',
];
