import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from '@LG_SHARED/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppComponent } from './app.component';
import { MatIconRegistry } from '@angular/material/icon';
import icons from './app.icons.const';
import { environment } from '@LIB_UTIL/environments/environment';
import { QuillModule } from 'ngx-quill';
import { quillConfig } from '@LIB_UTIL/util/quill-editor';
import { NgxDaterangepickerMd } from '@LIB_UTIL/ngx-daterangepicker/daterangepicker.module';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { ProfileState } from '@LIB_UTIL/profile/state/profile.state';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { AuthInterceptor } from '@LIB_UTIL/auth/interceptor/auth.interceptor';
import { DashboardState } from './state/dashboard/dashboard.state';
import { CropGroupsState } from '@LG_ROOT/app/state/cropgroups/crop-groups.state';
import { LgDateRangePickerModule } from '@LIB_UTIL/components/daterangepicker/date-range-picker.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const letsgrowModules: any[] = [LgDateRangePickerModule];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    NgxsModule.forRoot([LayoutState, AuthState, ProfileState, DashboardState, CropGroupsState], {
      developmentMode: !environment.production,
    }),
    ...letsgrowModules,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxDaterangepickerMd.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      logger: console,
      collapsed: false,
      // Set 'disabled' to false if you want to see all Store actions logged in the console
      disabled: true,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxSpinnerModule,
    QuillModule.forRoot(quillConfig),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Title,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer
  ) {
    icons.forEach((icon: string) => {
      let filename: string = '';
      let aliases: string[] = [];

      if (Array.isArray(icon)) {
        [filename] = icon;
        aliases = icon;
      } else {
        filename = icon;
        aliases = [icon];
      }

      aliases.forEach((alias: string) =>
        matIconRegistry.addSvgIcon(alias, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${filename}.svg`))
      );
    });
  }
}
