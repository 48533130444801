import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../projects/lib-util/src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function initializeAppcuesComponent(): any;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line @typescript-eslint/typedef
  .catch((err) => console.error(err));

initializeAppcuesComponent();
