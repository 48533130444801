import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetTimeZoneResponse, TimeZone } from './timezone-api.model';

export const iSiiVersionEndpoint: string = 'http://localhost:60000/isa/isecodde.dll?GetVersion&';

/**
 * The default timezone mechanism for the iSii does not work.
 * Because the iSii is configured using the UTC timezone. When
 * starting a new Angular session we check if the LetsGrow UI
 * is run on a normal desktop or on an iSii by performing a
 * call to localhost.
 *
 * @see TimezoneApiService.getISiiResponse()
 *
 * When this endpoint returns a 200 we are running the app on
 * an iSii and we have to perform a subsequent call to ip-api.com.
 * To get the timezone based on the user's ip-adres. The result
 * will be saved into the sessionStorage and used in date
 * formatting methods.
 *
 * @see TimezoneApiService.getUserTimeZoneForISii()
 */
@Injectable({
  providedIn: 'root',
})
export class TimezoneApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Make a call to localhost:6000. When LetsGrow runs on an iSii this
   * will respond with a 200. Otherwise it will timeout.
   * We do not care about the response of this endpoint.
   */
  public getISiiResponse(): Observable<string> {

    let headers: HttpHeaders = new HttpHeaders()
      .set('Accept', 'text/xml')
      // Set header to text/plain to prevent preflight request.
      .set('Content-Type', 'text/plain');

    return this.http.get(iSiiVersionEndpoint, {
      headers: headers,
      responseType: 'text',
    });
  }

  /**
   * Make a call to a public api to retrieve the iSii's timezone.
   * API key is registered to rpe@letsgrow.com
   */
  public getUserTimeZoneForISii(): Observable<TimeZone> {
    const url: string = 'https://api.bigdatacloud.net/data/ip-geolocation?key=4d08fea1303e453ab47182c4b1f49cd4';

    return this.http.get<GetTimeZoneResponse>(url).pipe(
      map(({ location }: GetTimeZoneResponse) => location.timeZone)
    );
  }
}
