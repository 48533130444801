import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { defaultTimezone } from '@LIB_UTIL/util/date';
import { TimezoneApiService } from './timezone-api.service';
import { TimeZone } from '@LIB_UTIL/timezone/timezone-api.model';
import { CookieService } from 'ngx-cookie-service';

/**
 * See explanation in TimezoneApiService.
 *
 * @see TimezoneApiService
 */
@Injectable({
  providedIn: 'root',
})
export class TimezoneResolver {

  private timezoneService: TimezoneApiService = inject(TimezoneApiService);
  private cookieService: CookieService = inject(CookieService);

  /**
   * Check if there is a timezone already in the sessionStorage.
   * Otherwise fetch the device timezone and put it in the storage.
   */
  public resolve(): Observable<string> | string {
    return this.createTimezoneObserver();
  }

  /**
   * For the iSii we cannot use the default timezone mechanism.
   * To determine te timezone we have to make a call to ip-api.
   */
  public createTimezoneObserver(): Observable<string> {

    return this.isUserOnISii().pipe(
      switchMap((isOnISii: boolean) => {
        sessionStorage.removeItem('timezoneOffset');
        if (isOnISii) {
          return this.timezoneService.getUserTimeZoneForISii().pipe(
            // Store the result in the session for the next http call.
            tap((iSiiTimezone: TimeZone) => {
              if (iSiiTimezone) {
                //response is valid, set timezone and offset
                sessionStorage.setItem('timezone', iSiiTimezone.ianaTimeId);
                sessionStorage.setItem('timezoneOffset', (iSiiTimezone.utcOffsetSeconds / 60).toString());
              } else {
                //response is invalid, set default timezone and remove offset
                sessionStorage.setItem('timezone', defaultTimezone);
              }
            }),
            switchMap((iSiiTimezone: TimeZone) => iSiiTimezone.ianaTimeId),
            // If device is not an iSii just return the default timezone.
            catchError((exception) => {
              //response is invalid/fault, set default timezone and remove offset
              sessionStorage.setItem('timezone', defaultTimezone);
              sessionStorage.removeItem('timezoneOffset');

              return of(defaultTimezone);
            }),
            take(1));
        } else {
          sessionStorage.setItem('timezone', defaultTimezone);

          return of(defaultTimezone);
        }
      })
    );
  }

  public isUserOnISii(): Observable<boolean> {
    const isISiiDevice: string = this.cookieService.get('IsISiiDevice');
    if (isISiiDevice && isISiiDevice.length > 0) {
      if (isISiiDevice === 'false') {

        return of(false);
      }

      return of(true);
    } else {

      return this.timezoneService.getISiiResponse()
        .pipe(
          timeout(1500),
          map(data => {
            if (data) {
              this.cookieService.set('IsISiiDevice', 'true', 1000);

              return true;
            }
            this.cookieService.set('IsISiiDevice', 'false', 1000);

            return false;
          }),
          catchError((exception) => {
            this.cookieService.set('IsISiiDevice', 'false', 1000);

            return of(false);
          })
        );
    }
  }
}
